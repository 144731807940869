import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, Route } from '@angular/router';
import { CurrentUser } from '../auth/current-user';
import { LocalStorage } from '../core/services/local-storage.service';
import { MemoryStorage } from '@common/core/services/memory-storage.service';
import { MaskStates } from '@common/auth/mask-states';

@Injectable({
    providedIn: 'root',
})
export class MaskGuard implements CanActivate, CanActivateChild, CanLoad {
    private memoryStorage: MemoryStorage = null;

    constructor(
        private currentUser: CurrentUser,
        private router: Router,
        private localStorage: LocalStorage,
        private maskStates: MaskStates,
    ) {
        this.memoryStorage = MemoryStorage.getInstance();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handle(state.url);
    }

    canLoad(route: Route) {
        return this.handle(route.path);
    }

    getMaskActivated() {
        let value = this.memoryStorage.get('maskActivated');
        if (typeof value !== 'boolean') {
            value = this.memoryStorage.set('maskActivated', true);
        }
        return value;
    }

    private handle(url: string) {
        const { pin = null, mask = null } = this.maskStates.getPinMask();
        const maskActivated = this.getMaskActivated();
        const pinInserted = sessionStorage.getItem('pinEntered');
        const isUserLogged = this.currentUser.isLoggedIn();
        if (isUserLogged && (this.currentUser.get('mask') !== this.maskStates.getMaskByDomain())) {
            this.router.navigate(['/invalid-domain']);
            return true;
        }

        if (!pin || !mask || !maskActivated || pinInserted || !isUserLogged) {
            return true;
        }
        const { availableMaskStates } = this.maskStates;
        const maskAvailable = availableMaskStates
            .find((maskState) => maskState === mask);
        const redirectPath = maskAvailable || availableMaskStates[0];
        if (url === redirectPath) {
            return true;
        }

        this.currentUser.redirectUri = url;
        this.currentUser.currentUrl = redirectPath;

        this.router.navigate([`/${redirectPath}`]);

        return false;
    }

    public lockApp() {
        const { pin, mask = null } = this.maskStates.getPinMask();
        if (!pin || !mask) {
            return true;
        }
        const { availableMaskStates } = this.maskStates;
        const maskAvailable = availableMaskStates
            .find((maskState) => maskState === mask);

        const redirectPath = maskAvailable || availableMaskStates[0];
        sessionStorage.removeItem('pinEntered');
        this.router.navigate([`/${redirectPath}`]);
    }
}
