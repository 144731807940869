import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { SkinComponent } from './skin/skin.component';
import { InvalidDomainComponent } from './skin/invalid-domain/invalid-domain.component';
import { HomeRedirectGuard } from '@common/guards/home-redirect-guard';
import { MaskGuard } from '@common/guards/mask-guard.service';
import { ContactComponent } from '@common/contact/contact.component';
import { NOT_FOUND_ROUTES } from '@common/pages/not-found-routes';

const routes: Routes = [
    {path: '', pathMatch: 'full', component: HomepageComponent, canActivate: [HomeRedirectGuard]},
    {path: 'calculator', pathMatch: 'full', component: SkinComponent},
    {path: 'default', pathMatch: 'full', component: SkinComponent},
    {path: 'invalid-domain', pathMatch: 'full', component: InvalidDomainComponent},
    {
        path: 'admin',
        loadChildren: () => import('src/app/admin/app-admin.module').then(m => m.AppAdminModule),
        canActivate: [MaskGuard],
        canActivateChild: [MaskGuard]
    },
    {
        path: 'drive',
        loadChildren: () => import('src/app/drive/drive.module').then(m => m.DriveModule),
        canActivate: [MaskGuard],
        canActivateChild: [MaskGuard]
    },
    {
        path: 'billing',
        loadChildren: () => import('@common/billing/billing.module').then(m => m.BillingModule),
        canActivate: [MaskGuard],
        canActivateChild: [MaskGuard]
    },
    {
        path: 'notifications',
        loadChildren: () => import('@common/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [MaskGuard],
        canActivateChild: [MaskGuard]
    },
    {path: 'contact', component: ContactComponent, canActivate: [MaskGuard], canActivateChild: [MaskGuard]},

    ...NOT_FOUND_ROUTES,
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
