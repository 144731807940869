import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { BrowserEvents } from '@common/core/services/browser-events.service';
import { AppHttpClient } from '@common/core/http/app-http-client.service';
import { Settings } from '@common/core/config/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import cssVars from 'css-vars-ponyfill';
import { CookieNoticeService } from '@common/gdpr/cookie-notice/cookie-notice.service';
import { CustomHomepage } from '@common/pages/shared/custom-homepage.service';
import {MemoryStorage} from '@common/core/services/memory-storage.service';
import {Subject} from 'rxjs';
import {CurrentUser} from '@common/auth/current-user';
import {LocalStorage} from '@common/core/services/local-storage.service';
import {MaskGuard} from '@common/guards/mask-guard.service';
import {MaskStates} from "@common/auth/mask-states";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    private memoryStorage: MemoryStorage;
    private userActivity;
    private userInactive: Subject<any> = new Subject();

    constructor(
        private browserEvents: BrowserEvents,
        private el: ElementRef,
        private http: AppHttpClient,
        private settings: Settings,
        private router: Router,
        private customHomepage: CustomHomepage,
        private cookieNotice: CookieNoticeService,
        private currentUser: CurrentUser,
        private localStorage: LocalStorage,
        private maskGuard: MaskGuard,
        private maskStates: MaskStates,
    ) {
        this.memoryStorage = MemoryStorage.getInstance();
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.memoryStorage.set('deferredPrompt', e);
        });
        const mqStandAlone = '(display-mode: standalone)';
        if ((navigator as any).standalone || window.matchMedia(mqStandAlone).matches) {
            this.memoryStorage.set('isPwa', true);
        }
        this.setTimeout();
        // this.userInactive.subscribe(() => this.checkToLock());
    }

    ngOnInit() {
        this.browserEvents.subscribeToEvents(this.el.nativeElement);
        this.settings.setHttpClient(this.http);

        // google analytics
        if (this.settings.get('analytics.tracking_code')) {
            this.triggerAnalyticsPageView();
        }

        // custom homepage
        this.customHomepage.select();
        this.loadCssVariablesPolyfill();
        this.cookieNotice.maybeShow();
        if (this.currentUser.isLoggedIn()) {
            this.maskStates.writeMask(this.currentUser.get('mask'));
            this.maskStates.writePin(this.currentUser.get('pin'));
        }
    }

    private triggerAnalyticsPageView() {
        this.router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if ( ! window['ga']) return;
                window['ga']('set', 'page', event.urlAfterRedirects);
                window['ga']('send', 'pageview');
            });
    }

    private loadCssVariablesPolyfill() {
        const isNativeSupport = typeof window !== 'undefined' &&
            window['CSS'] &&
            window['CSS'].supports &&
            window['CSS'].supports('(--a: 0)');
        if ( ! isNativeSupport) {
            cssVars();
        }
    }

    setTimeout() {
        this.userActivity = setTimeout(() => this.checkToLock(), Number(this.localStorage.get('idle', 60)) * 1000);
    }

    checkToLock() {
        const isIdleOn = this.localStorage.get('idleOn');
        if (isIdleOn && this.currentUser.isLoggedIn()) {
            this.userInactive.next(undefined);
            this.maskGuard.lockApp();
        }
    }

    @HostListener('window:mousemove') refreshUserState() {
        clearTimeout(this.userActivity);
        this.setTimeout();
    }
}
