import {
    Component,
    OnInit,
    AfterViewChecked,
    ChangeDetectionStrategy,
    Output,
    ViewChild,
    EventEmitter,
    ElementRef,
} from '@angular/core';
import { LocalStorage } from '@common/core/services/local-storage.service';
import { MaskStates } from '../mask-states';
import { MemoryStorage } from '@common/core/services/memory-storage.service';
import {CookieService} from '@common/core/services/cookie.service';
import {PwaDomains} from '@common/core/bootstrapper.service';
import {AuthService} from '@common/auth/auth.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'skin-setting-steps',
  templateUrl: './skin-setting-steps.component.html',
  styleUrls: ['./skin-setting-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkinSettingStepsComponent implements OnInit, AfterViewChecked {
    private memoryStorage: MemoryStorage = null;
    private userPin = '';
    private confirmUserPin = '';
    private mask = 'default';
    private currentStep = 1;
    public pinValidationError = '';
    private maxStep = 4;
    public shownMaxStep = 4;
    public skip = false;

    @ViewChild('inputWithoutFocus') inputWithoutFocus: ElementRef;
    @Output() maxStepReached = new EventEmitter<boolean>();

    constructor(
        private localStorage: LocalStorage,
        private maskStates: MaskStates,
        public auth: AuthService,
        public route: ActivatedRoute,
    ) {
        this.memoryStorage = MemoryStorage.getInstance();
        const { pin = null, mask = '' } = this.maskStates.getPinMask();
        this.userPin = pin;
        this.mask = mask;

        if (this.route.snapshot.fragment === 'skipSteps') {
            this.goNextStep(true);
        }
    }

    ngAfterViewChecked() {
        if (this.currentStep === 4 && this.inputWithoutFocus) {
            this.inputWithoutFocus.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        const mask = this.getMask();
        const pin = this.getUserPin();
        if (
            mask === this.maskStates.noMaskValue
            || (this.isPinLengthValid(pin) && this.maskStates.isMaskAvailable(mask))
        ) {
            this.setCurrentStepToMax();
            return;
        }
        if (!this.isPinLengthValid(pin) && this.maskStates.isMaskAvailable(mask)) {
            this.currentStep = 3;
        }
    }

    private setInitialMaskByDomain(): void {
        const maskValue: string = this.maskStates.getMaskByDomain();
        this.setMask(maskValue);
        if (maskValue === this.maskStates.noMaskValue) {
            return;
        }
        this.writeMask();
    }

    private redirectByMaskValue(): void {
        const mask: string = this.getMask() || this.maskStates.noMaskValue;
        const redirectDomain: string = this.maskStates.getMaskByDomainOrDomainByMask(mask);

        if (!redirectDomain) {
            return;
        }
        const redirectUrl = this.auth.getRedirectUri() || '';
        window.location.href = `http://${redirectDomain}/${redirectUrl}`;
    }

    public isNextStep(): boolean {
        return this.currentStep !== this.maxStep;
    }

    public increaseCurrentStep(): void {
        if (!this.isNextStep()) {
            return;
        }
        this.currentStep += 1;
    }

    public decreaseCurrentStep(): void {
        this.resetPinError();
        this.currentStep -= 1;
    }

    public getCurrentStep(): number {
        return this.currentStep;
    }

    public reset(): void {
        this.currentStep = 0;
        this.userPin = '';
        this.confirmUserPin = '';
        this.mask = 'default';
    }

    public getMaxStep(): number {
        return this.maxStep;
    }

    public setMask(mask): void {
        this.mask = mask;
    }

    public getMask(): string {
        return this.mask ? this.mask.toString() : '';
    }

    private writeMask(): void {
        const mask = this.getMask() || this.maskStates.noMaskValue;
        if (!this.maskStates.isMaskValid(mask)) {
            return;
        }

        this.setMask(mask);
        this.maskStates.writeMask(mask);
    }

    public isCurrentMask(mask): boolean {
        const isMaskValid = this.maskStates.isMaskValid(this.getMask());
        const comparedValue = isMaskValid ? this.getMask() : this.maskStates.noMaskValue;
        return comparedValue === mask;
    }

    public enterPin(event): void {
        if (!Number.isInteger(+event.key)) {
            return;
        }
        this.userPin = event.target.value;
    }

    public setCurrentStepToMax(): boolean {
        this.currentStep = this.getMaxStep();
        return !!this.currentStep;
    }

    public getUserPin(): string {
        return this.userPin ? this.userPin.toString() : '';
    }

    public enterConfirmPin(event): void {
        if (!Number.isInteger(+event.key)) {
            return;
        }
        this.confirmUserPin = event.target.value;
    }

    public getUserConfirmPin(): string {
        return this.confirmUserPin;
    }

    private isPinLengthValid(pin): boolean {
        return pin && pin.length > 3;
    }

    private isPinsEquals(): boolean {
        return this.getUserPin() === this.getUserConfirmPin();
    }

    public pinValidator(pin): boolean {
        if (!this.isPinLengthValid(pin)) {
            this.pinValidationError = 'Pin length must be more than 3 number symbols';
            return false;
        }
        return true;
    }

    public pinConfirmValidator(): boolean {
        if (!this.isPinsEquals()) {
            this.pinValidationError = 'Pins must equals';
            return false;
        }
        return true;
    }

    public resetPinError(): void {
        this.pinValidationError = '';
    }

    public stepsActions(): boolean {
        switch (this.getCurrentStep()) {
            case 2:
                this.writeMask();
                return true;
            case 3:
                return this.pinValidator(this.getUserPin());
            case 4:
                const validationResult = this.pinConfirmValidator();
                if (validationResult) {
                    this.maskStates.writePin(this.getUserPin());
                }
                return validationResult;
            default:
                return true;
        }
    }

    public goNextStep(isSkip = false): void {
        if (isSkip) {
            this.skip = true;
            this.setCurrentStepToMax();
            this.maskStates.writeDefaultMask();
        }
        const result = this.stepsActions();

        if (!result) {
            return;
        }

        if (this.isNextStep()) {
            this.resetPinError();
            this.increaseCurrentStep();
            return;
        } else {
            this.memoryStorage.set('maskActivated', false);
            if (this.getMask() !== this.maskStates.getMaskByDomain()) {
                this.redirectByMaskValue();
                return;
            }
            this.maxStepReached.emit(true);
        }
    }
}
