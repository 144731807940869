import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'any',
})
export class MemoryStorage {
    static instance: MemoryStorage = null;
    private readonly storage;

    static getInstance() {
        if (!MemoryStorage.instance) {
            MemoryStorage.instance = new MemoryStorage();
        }
        return MemoryStorage.instance;
    }

    constructor() {
        this.storage = {};
    }

    /**
     * Retrieve specified item from local storage.
     */
    public get<T>(key: string, defaultValue?: T): any|T {
        if ( ! this.storage) {
            return defaultValue;
        }

        let value;
        try {
            value = this.storage[key];
        } catch (e) {
            console.error('MemoryStorage:get got error', e);
            return defaultValue;
        }

        if (value === undefined) {
            return defaultValue;
        }

        const result = value;
        return result;
    }

    /**
     * Store specified item in local storage.
     */
    public set(key: string, value: any) {
        if ( ! this.storage) {
            return null;
        }
        this.storage[key] = value;
        return value;
    }

    /**
     * Remove specified item from local storage.
     */
    public remove(key: string) {
        if ( ! this.storage) return null;
        delete this.storage[key];
    }
}
