<div class="calc-wrapper">
    <ng-container *ngIf="(mask == maskStates.calculatorMask); else defaultPin">
        <div class="container">
            <div class="display-container">
                <p class="display">{{ equation.replace('*', '×') }}</p>
            </div>

            <div class="input-container">
                <div class="commandNum-container">
                    <div class="command-container">
                        <button class="calc-button" (click)="command('%')">%</button>
                        <button class="wide calc-button" (click)="concatOperator('AC')">AC</button>
                    </div>

                    <div class="number-container calc-container">
                        <button class="calc-button" (click)="updateCurrNum(7)">7</button>
                        <button class="calc-button" (click)="updateCurrNum(8)">8</button>
                        <button class="calc-button" (click)="updateCurrNum(9)">9</button>
                        <br>
                        <button class="calc-button" (click)="updateCurrNum(4)">4</button>
                        <button class="calc-button" (click)="updateCurrNum(5)">5</button>
                        <button class="calc-button" (click)="updateCurrNum(6)">6</button>
                        <br>
                        <button class="calc-button" (click)="updateCurrNum(1)">1</button>
                        <button class="calc-button" (click)="updateCurrNum(2)">2</button>
                        <button class="calc-button" (click)="updateCurrNum(3)">3</button>
                        <br>
                        <button class="zero calc-button" (click)="updateCurrNum(0)">0</button>
                        <button class="calc-button" (click)="addDecimal()">.</button>
                        <button class="calc-button" (click)="calculate()">=</button>
                    </div>
                </div>

                <div class="operator-container calc-container">
				<span>
					<button class="calc-button" (click)="command('DEL')">DEL</button>
                    <br>
                    <button class="calc-button" (click)="concatOperator('+')">+</button>
                    <br>
                    <button class="calc-button" (click)="concatOperator('-')">-</button>
                    <br>
                    <button class="calc-button" (click)="concatOperator('*')">×</button>
                    <br>
                    <button class="calc-button" (click)="concatOperator('/')">/</button>
                    <br>
				</span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #defaultPin>
        <div class="default-mask text-center">
            <h2 class="reg-title">
                Enter your pin
            </h2>
            <div class="input-container">
                <div class="default-mask-subtitle">
                    <label for="pin">Pin code</label>
                    <a class="forgot-link" (click)="forgotClick()">Forgot pin-code?</a>
                </div>
                <div class="default-mask-input-holder">
                    <input id="pin" type="number" class="default-mask-input" [class.default-mask-input--error]="pinValidationError" autofocus (keyup.enter)=checkPin(value) (keyup)="enterPin($event)">
                    <ng-container class="error" *ngIf="pinValidationError"><span class="error">This pin-code incorrect. Try again</span></ng-container>
                </div>
            </div>
            <button (click)="checkPin(value)" mat-raised-button class="secondary-button btn-arrow" color="accent">
                Confirm
            </button>
        </div>
    </ng-template>
</div>
