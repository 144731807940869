import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    OnInit,
    Optional,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Users } from '../auth/users.service';
import { AuthService } from '../auth/auth.service';
import { Toast } from '../core/ui/toast.service';
import { UploadsApiService } from '../uploads/uploads-api.service';
import { Settings } from '../core/config/settings.service';
import { Translations } from '../core/translations/translations.service';
import { Localizations } from '../core/translations/localizations.service';
import { openUploadWindow } from '../uploads/utils/open-upload-window';
import { AvatarValidator } from './avatar-validator';
import { UploadInputTypes } from '../uploads/upload-input-config';
import { CurrentUser } from '../auth/current-user';
import { ACCOUNT_SETTINGS_PANELS } from './account-settings-panels';
import { ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AccountSettingsResolverData } from '@common/account-settings/account-settings-resolve.service';
import { SelectOptionLists } from '@common/core/services/value-lists.service';
import { User } from '@common/core/types/models/User';
import { BreakpointsService } from '@common/core/ui/breakpoints.service';
import { BackendErrorResponse } from '@common/core/types/backend-error-response';
import { LocalStorage } from '@common/core/services/local-storage.service';
import { MaskStates } from '@common/auth/mask-states';

@Component({
    selector: 'account-settings',
    templateUrl: './account-settings.component.html',
    styleUrls: ['./account-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsComponent implements OnInit, AfterViewInit {
    @ViewChild('extraPanelRef', { read: ViewContainerRef }) extraPanelRef: ViewContainerRef;

    public loading$ = new BehaviorSubject<boolean>(false);
    public avatar$ = new BehaviorSubject(null);
    public initialUser$ = new BehaviorSubject<User>(null);
    public userPin: string;
    public additionalPinClass$ = new BehaviorSubject('');
    public reinstallLink = new BehaviorSubject('');
    public pinInputType$ = new BehaviorSubject('password');

    public userErrors$ = new BehaviorSubject<{
        first_name?: string,
        last_name?: string,
        pin?: string,
        mask?: string,
        country?: string,
        language?: string,
        timezone?: string,
    }>({});

    public passwordErrors$ = new BehaviorSubject<{
        current_password?: string,
        new_password?: string,
        new_password_confirmation?: string,
    }>({});

    public userForm = this.fb.group({
        first_name: [''],
        last_name: [''],
        pin: [''],
        mask: [''],
        language: [''],
        timezone: [''],
        country: [''],
    });

    public passwordForm = this.fb.group({
        current_password: [''],
        new_password: [''],
        new_password_confirmation: [''],
    });

    public selects: SelectOptionLists = {
        timezones: {},
        countries: [],
        localizations: [],
        masks: this.maskStates.validMaskStates,
        idle: [
            {value: 60, name: '1 min'},
            {value: 300, name: '5 min'},
            {value: 900, name: '15 min'},
            {value: 1800, name: '30 min'},
            {value: 3600, name: '1 hour'}
        ]
    };

    constructor(
        public settings: Settings,
        private route: ActivatedRoute,
        private users: Users,
        public currentUser: CurrentUser,
        private toast: Toast,
        private uploads: UploadsApiService,
        private i18n: Translations,
        private localizations: Localizations,
        public auth: AuthService,
        private avatarValidator: AvatarValidator,
        private fb: FormBuilder,
        private componentFactoryResolver: ComponentFactoryResolver,
        public breakpoints: BreakpointsService,
        private cd: ChangeDetectorRef,
        private localStorage: LocalStorage,
        private maskStates: MaskStates,
        @Optional() @Inject(ACCOUNT_SETTINGS_PANELS) public extraPanels: {component: ComponentType<any>}[]
    ) {}

    ngOnInit() {
        this.route.data.subscribe((data: {api: AccountSettingsResolverData}) => {
            if (data?.api?.user?.mask === this.maskStates.noMaskValue) {
                this.additionalPinClass$.next('hidden');
            }
            this.initialUser$.next(data.api.user);
            this.userForm.patchValue(data.api.user);
            this.avatar$.next(data.api.user.avatar);
            this.selects = { ...this.selects, ...data.api.selects };
            this.selects.mask = this.maskStates.validMaskStates;
        });
    }

    public togglePinType(): void {
        const currentType = this.pinInputType$.getValue();
        this.pinInputType$.next(currentType === 'password' ? 'text' : 'password');
    }

    public getPinIcon(): string {
        const currentType = this.pinInputType$.getValue();
        return currentType === 'password' ? 'visibility-off.svg' : 'visibility.svg';
    }

    public clearCookies(): void {
        this.maskStates.clearCookies();
    }

    ngAfterViewInit() {
        this.loadExtraPanels();
    }

    private getPinError(pin: string): string {
        let error = '';
        const regexp = /\D/g;
        if (pin.length < 4) {
            error += 'Pin should contain more than 3 digits. ';
        }
        if (regexp.test(pin)) {
            error += 'Pin should contain only digits';
        }
        return error;
    }

    public getUserPin(): string {
        return this.userPin;
    }

    public enterPin(event): void {
        if (event.key === 'e' || event.key === 'E') {
            event.preventDefault();
            return;
        }
        this.userPin = event.target.value;
    }

    public enterIdle(event): void {
        this.localStorage.set('idle', event.target.value);
    }

    public toggleIdle(event): void {
        this.localStorage.set('idleOn', event.target.checked);
    }

    public getIdle(): string {
        return this.localStorage.get('idle', '');
    }

    public isIdleOn(): boolean {
        return this.localStorage.get('idleOn', '');
    }

    public updateMaskDropdown(event) {
        let classValue = '';
        if (event === this.maskStates.noMaskValue) {
            classValue = 'hidden';
        }
        this.additionalPinClass$.next(classValue);
    }

    private updateMaskData(userForm) {
        const pin = userForm?.pin?.toString ? userForm.pin.toString() : userForm?.pin;
        const { pin: pinFromCookies = null, mask: maskFromCookies = null } = this.maskStates.getPinMask();
        const mask = userForm.mask || maskFromCookies;
        if (!pin && !pinFromCookies) {
            return true;
        }
        const pinErrorMessage = this.getPinError(pin);
        if (
            mask !== this.maskStates.noMaskValue
            && pinErrorMessage.length
        ) {
            this.userErrors$.next({ pin: `Invalid pin. ${pinErrorMessage}` });
            return false;
        }
        this.maskStates.writeMask(mask);
        this.maskStates.writePin(pin);
        this.userErrors$.next({});
        return true;
    }

    public getDomainReinstall(): void {
        const initialMask = this.initialUser$.value.mask;
        const formMask = this.userForm.value.mask;

        if (initialMask === formMask) {
            return;
        }

        this.reinstallLink.next(this.maskStates.getMaskByDomainOrDomainByMask(formMask));
    }

    public updateAccountSettings() {
        const isUpdatedSuccessfully = this.updateMaskData(this.userForm?.value);
        if (!isUpdatedSuccessfully) {
            return;
        }

        this.loading$.next(true);
        this.getDomainReinstall();
        this.users.update(this.currentUser.get('id'), this.userForm.value)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe((data) => {
                this.initialUser$.next((data as any).user);
                this.toast.open('Account settings updated');
                this.userErrors$.next({});
            }, (errResponse: BackendErrorResponse) => {
                console.log('errResponse', errResponse);
                return this.userErrors$.next(errResponse.errors);
            });
    }

    public openAvatarUploadDialog() {
        this.loading$.next(true);
        openUploadWindow({types: [UploadInputTypes.image]}).then(files => {
            if (this.avatarValidator.validateWithToast(files[0]).failed) {
                return;
            }
            this.users.uploadAvatar(this.currentUser.get('id'), files)
                .pipe(finalize(() => this.loading$.next(false)))
                .subscribe(response => {
                    this.userForm.patchValue({avatar: response.user.avatar});
                    this.currentUser.set('avatar', response.user.avatar);
                    this.avatar$.next(response.user.avatar);
                    this.toast.open('Avatar updated');
                }, (errResponse: BackendErrorResponse) => {
                    const key = Object.keys(errResponse.errors)[0];
                    this.toast.open(errResponse.errors[key]);
                });
        });
    }

    public deleteAvatar() {
        this.loading$.next(true);
        this.users.deleteAvatar(this.currentUser.get('id'))
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(user => {
                this.userForm.patchValue({avatar: user.avatar});
                this.currentUser.set('avatar', user.avatar);
                this.avatar$.next(user.avatar);
                this.toast.open('Avatar removed');
            });
    }

    public changeUserPassword() {
        this.loading$.next(true);
        this.users.changePassword(this.currentUser.get('id'), this.passwordForm.value)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(() => {
                this.toast.open('Password updated');
                this.passwordErrors$.next({});
                this.passwordForm.reset();
                this.currentUser.set('has_password', true);
                window.location.reload();
            }, (errResponse: BackendErrorResponse) => this.passwordErrors$.next(errResponse.errors));
    }

    public changeLanguage(name: string) {
        this.loading$.next(true);
        this.localizations.get(name)
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe(response => {
                this.i18n.setLocalization(response.localization);
            });
    }

    private loadExtraPanels() {
        if ( ! this.extraPanels || ! this.extraPanels.length) {
            return;
        }
        this.extraPanels.forEach((panelComp: {component: ComponentType<any>}) => {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(panelComp.component);
            this.extraPanelRef.clear();
            const componentRef = this.extraPanelRef.createComponent(componentFactory);
            componentRef.instance.user = this.initialUser$.value;
            this.cd.detectChanges();
        });
    }

    public apiEnabled(): boolean {
        return this.settings.get('api.integrated') && this.currentUser.hasPermission('api.access');
    }
}
