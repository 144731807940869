import { Injectable } from '@angular/core';
import { Bootstrapper, PwaDomains } from '@common/core/bootstrapper.service';
import { CookieService } from '@common/core/services/cookie.service';

@Injectable({
    providedIn: 'any'
})
export class MaskStates {
    public calculatorMask = 'calculator';
    public textEditorMask = 'text_editor';
    public noMaskValue = 'default';
    public availableMaskStates = [this.calculatorMask, this.noMaskValue];
    public validMaskStates = [this.calculatorMask, this.noMaskValue];
    private readonly pwaDomains: PwaDomains;
    private domainMaskAdapter: any = {};
    private generalDomain: string;

    constructor(
        private bootstrapper: Bootstrapper,
        private cookie: CookieService,
    ) {
        this.pwaDomains = this.bootstrapper?.data?.pwa_domains;
        this.domainMaskAdapter[this.domainMaskAdapter[this.pwaDomains.DOMAIN_CALC] = this.calculatorMask] = this.pwaDomains.DOMAIN_CALC;
        this.domainMaskAdapter[this.domainMaskAdapter[this.pwaDomains.DOMAIN_TEXT] = this.textEditorMask] = this.pwaDomains.DOMAIN_TEXT;
        this.domainMaskAdapter[this.domainMaskAdapter[this.pwaDomains.DOMAIN_PRIVATE] = this.noMaskValue] = this.pwaDomains.DOMAIN_PRIVATE;
        this.generalDomain = this.pwaDomains.DOMAIN_PRIVATE;
        const strArr = this.generalDomain.split('.');
        if (strArr.length > 2) {
            this.generalDomain = strArr.slice(strArr.length - 2).join('.');
        }
    }

    public writeDefaultMask(): void {
        this.writeMask(this.noMaskValue);
    }

    public writeMask(mask: string): void {
        this.cookie.set('mask', mask, 30, this.generalDomain);
    }

    public writePin(pin: string): void {
        this.cookie.set('pin', pin, 30, this.generalDomain);
    }

    public getPinMask(): {pin: string, mask: string} {
        const pin = this.cookie.get('pin');
        const mask = this.cookie.get('mask');
        return {
            pin: pin ? pin.toString() : null,
            mask: mask ? mask.toString() : null,
        };
    }

    public isMaskValid(mask: string): boolean {
        return this.validMaskStates
            .some((availableMask) => mask === availableMask);
    }

    public isMaskAvailable(mask: string): boolean {
        return this.availableMaskStates
            .some((availableMask) => mask === availableMask);
    }

    public getMaskByDomainOrDomainByMask(value: string): string|null {
        const outputValue: string = this.domainMaskAdapter[value];
        if (!outputValue) {
            console.error('Failed to determine current domain correspondance with available domains');
            return null;
        }
        return outputValue;
    }

    public getMaskByDomain(): string|null {
        const domain: string = window.location.host;
        return this.getMaskByDomainOrDomainByMask(domain);
    }

    public clearCookies(): void {
        const cookiesArr = document.cookie.split(';');
        cookiesArr.forEach((cookieItem) => {
            const cookieName = cookieItem.slice(0, cookieItem.indexOf('='));
            this.cookie.set(cookieName, '', 0, this.generalDomain);
        });
    }
}
