import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    HostListener
} from '@angular/core';
import {Router} from '@angular/router';
import {MemoryStorage} from '@common/core/services/memory-storage.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@common/auth/auth.service';
import {CurrentUser} from '@common/auth/current-user';
import {MaskStates} from '@common/auth/mask-states';

@Component({
    selector: 'skin',
    templateUrl: './skin.component.html',
    styleUrls: ['./skin.component.scss', '../../common/auth/skin-setting-steps/skin-setting-steps.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkinComponent implements OnInit {
    operatorLastUsed = false;
    value = '0';
    isFloat = false;
    isInit = true;
    isOff = false;
    memoryStorage = null;
    mask = 'default';
    public pinValidationError = false;
    constructor(
        private currentUser: CurrentUser,
        private router: Router,
        public maskStates: MaskStates,
        private route: ActivatedRoute,
        private auth: AuthService,
    ) {
        this.memoryStorage = MemoryStorage.getInstance();
    }

    ngOnInit(): void {
        this.mask = this.maskStates.getPinMask().mask;
    }

    get equation() {
        return this.value;
    }

    set equation(val) {
        this.value = val;

    }

    checkPin(val) {
        const { pin } = this.maskStates.getPinMask();
        if (!pin || pin.toString() === val) {
            const setResult = this.memoryStorage.set('maskActivated', false);
            sessionStorage.setItem('pinEntered', 'true');
            this.currentUser.currentUrl = null;
            const redirectUrl = this.auth.getRedirectUri();
            this.router.navigateByUrl(redirectUrl);
        } else {
            this.pinValidationError = true;
        }
    }

    concatOperator(operator) {
        if (operator === 'AC') {
            this.equation = '0';
            this.isInit = true;
        } else {
            if (!this.equation[this.equation.length - 1].match(/[-+*\/]/)) {
                this.equation += operator;
                this.isFloat = false;
            }
        }
    }

    command(command) {
        if (command === '%') {
            if (!this.equation[this.equation.length - 1].match('%')) {
                this.equation += '%';
            }
        } else if (command === 'DEL') {
            if (this.equation.length === 1) {
                this.equation = this.equation.substring(0, this.equation.length - 1);
                this.equation = '0';
                this.isInit = true;
            } else {
                this.equation = this.equation.substring(0, this.equation.length - 1);
            }
        }
    }

    addDecimal() {
        this.isFloat = true;
        this.equation += '.';
    }

    updateCurrNum(num) {
        if (this.isInit) {
            this.equation = num.toString();
            this.isInit = false;
        } else {
            this.equation += num;
        }
    }

    calculate() {
        this.checkPin(this.value);
        this.equation = eval(this.equation).toString();
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.mask !== this.maskStates.calculatorMask) {
            return;
        }
        const { key } = event;
        const validNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        const concatOperators = ['+', '-', '/', '*'];
        if (['Backspace', 'Delete'].indexOf(key) >= 0) {
            this.command('DEL');
        } else if (key === '%') {
            this.command('%');
        } else if (key === '.') {
            this.addDecimal();
        } else if (validNumbers.indexOf(key) >= 0) {
            this.updateCurrNum(+validNumbers[validNumbers.indexOf(key)]);
        } else if (concatOperators.indexOf(key) >= 0) {
            this.concatOperator(concatOperators[concatOperators.indexOf(key)]);
        } else if (key === 'Enter' || key === '=') {
            this.calculate();
        }
    }

    public enterPin(event): void {
        this.pinValidationError = false;
        this.value = event.target.value;
    }

    public forgotClick(): void {
        sessionStorage.setItem('pinEntered', 'true');
        this.auth.logOut('/account/settings');
    }
}
