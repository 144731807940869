<div class="window window-height">
    <ng-container *ngIf="getCurrentStep() === 1">
        <div class="reg-header">
            <img src="/client/assets/images/registration/initial-step.svg" alt="Initial setup" class="image-center">
        </div>
        <div class="reg-content">
            <h2 class="reg-title">Initial Setup</h2>
            <p class="text-center">
                Welcome to Private Folder. Lock and Hide all your Personal Files and Folderswith this Application. Now, let us help you set it up!
            </p>
        </div>
        <div class="flex-between reg-footer">
            <span (click)="goNextStep(true)" class="link-back">Skip</span>
            <span class="step-count">{{ getCurrentStep() }} / {{ shownMaxStep }}</span>
            <button (click)="goNextStep()" mat-raised-button class="secondary-button btn-arrow" color="accent">Next</button>
        </div>
    </ng-container>

    <ng-container *ngIf="getCurrentStep() === 2">
        <div class="reg-header">
            <h2 class="reg-title">Choose mask for entering</h2>
            <p class="text-center">
                For Perfect Disguise, Kindly Select Your Masking Interface from the OptionBelow.
            </p>
        </div>
        <div class="flex-between reg-content">
            <div (click)="setMask('default')" [ngClass]="{'highlight': isCurrentMask('default')}" class="mask-item">
                <div class="mask-item-title">DEFAULT</div>
                <div class="mask-item-image">
                    <img src="/client/assets/images/registration/default.svg" alt="Default">
                </div>
            </div>
            <div (click)="setMask('calculator')" [ngClass]="{'highlight': isCurrentMask('calculator')}" class="mask-item">
                <div class="mask-item-title">CALCULATOR</div>
                <div class="mask-item-image">
                    <img src="/client/assets/images/registration/calculator.svg" alt="Calculator">
                </div>
            </div>
            <div [ngClass]="{'highlight': isCurrentMask('text_editor')}" class="mask-item no-pointer" disabled>
                <div class="mask-item-title">TEXT EDITOR</div>
                <div class="mask-item-image">
                    <img src="/client/assets/images/registration/text-editor.svg" alt="Text Editor">
                </div>
                <div class="mask-item-coming-soon">COMING SOON</div>
            </div>
        </div>
        <div class="flex-between reg-footer">
            <span (click)="decreaseCurrentStep()" class="link-back">Back</span>
            <span class="step-count">{{ getCurrentStep() }} / {{ shownMaxStep }}</span>
            <button (click)="goNextStep()" mat-raised-button class="secondary-button btn-arrow" color="accent">Next</button>
        </div>
    </ng-container>

    <ng-container *ngIf="getCurrentStep() === 3">
        <div class="reg-header">
            <h2 class="reg-title">Create pin-code</h2>
            <p class="text-center">Create and Enter Your Secret Pin-Code for Restricted Access and Protectionto your Personal Files</p>
        </div>
        <div class="reg-content">
            <div class="input-container w-50">
                <label for="pinCode" trans>Pin-code</label>
                <input type="number" id="pinCode" class="no-arrow disk-mask" [value]="getUserPin()" (keyup.enter)=goNextStep() (keyup)="enterPin($event)" autofocus/>
                <ng-container class="error" *ngIf="pinValidationError"><p><span class="error">{{ pinValidationError }}</span></p></ng-container>
            </div>
        </div>
        <div class="flex-between reg-footer">
            <span (click)="decreaseCurrentStep()" class="link-back">Back</span>
            <span class="step-count">{{ getCurrentStep() }} / {{ shownMaxStep }}</span>
            <button (click)="goNextStep()" mat-raised-button class="secondary-button btn-arrow" color="accent">Next</button>
        </div>
    </ng-container>

    <ng-container *ngIf="getCurrentStep() === 4 && !skip">
        <div class="reg-header">
            <h2 class="reg-title">Confirm pin-code</h2>
            <p class="text-center">Kindly Re-enter Your Secret Pin-Code Below to Confirm.</p>
        </div>
        <div class="reg-content">
            <div class="input-container w-50">
                <label for="pinCodeConfirm" trans>Pin-code</label>
                <input #inputWithoutFocus id="pinCodeConfirm" class="no-arrow disk-mask" [value]="getUserConfirmPin()" type="number" (keyup.enter)=goNextStep() (keyup)="enterConfirmPin($event)" autofocus/>
                <ng-container class="error" *ngIf="pinValidationError"><p><span class="error">{{ pinValidationError }}</span></p></ng-container>
            </div>
        </div>
        <div class="flex-between reg-footer">
            <span (click)="decreaseCurrentStep()" class="link-back">Back</span>
            <span class="step-count">{{ getCurrentStep() }} / {{ shownMaxStep }}</span>
            <button (click)="goNextStep()" mat-raised-button class="secondary-button btn-arrow" color="accent">Next</button>
        </div>
    </ng-container>

<!--    <ng-container *ngIf="getCurrentStep() === 5">-->
<!--        <div class="reg-content">-->
<!--            <div class="button-container w-50">-->
<!--                <button (click)="installApp(false)" mat-raised-button class="secondary-button" color="accent">Install App</button>-->
<!--                <button (click)="goNextStep()" mat-raised-button class="skip-button" color="accent">Skip Install</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </ng-container>-->
</div>
