import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth/auth.service';
import {CurrentUser} from '../auth/current-user';

@Injectable({
    providedIn: 'root',
})
export class HomeRedirectGuard implements CanActivate {
    constructor(
        private currentUser: CurrentUser,
        private auth: AuthService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.currentUser.currentUrl = null;
        this.router.navigate([this.auth.getRedirectUri()]);

        return false;
    }
}
