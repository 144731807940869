import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
} from '@angular/core';
import {Router} from '@angular/router';
import {MemoryStorage} from '@common/core/services/memory-storage.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '@common/auth/auth.service';
import {CurrentUser} from '@common/auth/current-user';
import {MaskStates} from '@common/auth/mask-states';
import {Users} from '@common/auth/users.service';
import {finalize} from 'rxjs/operators';
import {BackendErrorResponse} from '@common/core/types/backend-error-response';
import {Toast} from '@common/core/ui/toast.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'skin',
    templateUrl: './invalid-domain.component.html',
    styleUrls: ['./invalid-domain.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvalidDomainComponent implements OnInit {
    public loading$ = new BehaviorSubject<boolean>(false);
    memoryStorage = null;
    public domainMask = null;
    public settingsMask = null;
    public userErrors$ = new BehaviorSubject<{
        first_name?: string,
        last_name?: string,
        pin?: string,
        mask?: string,
        country?: string,
        language?: string,
        timezone?: string,
    }>({});
    public reinstallLink = new BehaviorSubject('');
    constructor(
        public currentUser: CurrentUser,
        private router: Router,
        public maskStates: MaskStates,
        private route: ActivatedRoute,
        private auth: AuthService,
        private users: Users,
        private toast: Toast,
    ) {
        this.memoryStorage = MemoryStorage.getInstance();
    }

    ngOnInit(): void {
        this.domainMask = this.maskStates.getMaskByDomain();
        this.settingsMask = this.currentUser.get('mask');
        if (this.domainMask === this.settingsMask) {
            this.router.navigateByUrl('/drive');
        }
        this.reinstallLink.next(this.maskStates.getMaskByDomainOrDomainByMask(this.settingsMask));
    }

    public updateUserMask() {
        this.users.update(this.currentUser.get('id'), {mask: this.domainMask, pin: this.currentUser.get('pin')})
            .pipe(finalize(() => this.loading$.next(false)))
            .subscribe((data) => {
                this.toast.open('Account settings updated');
                this.userErrors$.next({});
                this.currentUser.set('mask', this.domainMask);
                this.router.navigateByUrl('/drive');
            }, (errResponse: BackendErrorResponse) => {
                console.log('errResponse', errResponse);
                return this.userErrors$.next(errResponse.errors);
            });
    }

    public reinstall() {
        window.open(`//${this.reinstallLink.getValue()}`, '_blank');
    }
}
