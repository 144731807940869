<div class="content-wrapper">
    <div class="pane">
        <div class="invalid-domain-message">
            You are currently using the <strong>"{{domainMask}}"</strong> version of the application.
            And the <strong>"{{settingsMask}}"</strong> was chosen as a mask.
        </div>
        <div class="invalid-domain-cloud">
            To use the <span class="capitalize">"{{settingsMask}}"</span> mask, please reinstall the application or return to the <span class="capitalize">"{{domainMask}}"</span> mask.
        </div>
        <div class="invalid-domain-buttons">
            <button (click)="reinstall()"  mat-stroked-button class="secondary-button" color="accent" [disabled]="loading$.getValue()">
                Reinstall
            </button>
            <button (click)="updateUserMask()" mat-raised-button class="primary-button" color="accent" [disabled]="loading$.getValue()">
                Return to <span class="capitalize">{{domainMask}}</span>
            </button>
        </div>
    </div>
</div>
